<template>
  <div class="financial" id="financial">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'financial'
}
</script>

<style scoped>
.financial {
  width: 100%;
  height: 100%;
}
</style>
